<template>
  <div id="unit">
    <div class="unit-management">
      <strong style="font-size:13px">单位管理</strong>
      <div class="buttons">
        <el-button @click="editAddUnit()" type="primary"  style="line-height: 20px;" size="mini"  v-if = "level == 1">创建一级单位
        </el-button>
        <el-button @click="addTwoUnit()" type="primary" style="line-height: 20px;"  size="mini"  v-if = "level != 3 && unitlevel == 1 | level == 1">创建二级单位
        </el-button>
        <el-button @click="addThreeUnit()" type="primary"  style="line-height: 20px;" size="mini"  v-if = "level != 3 && unitlevel == 2 | level == 1">创建三级单位
        </el-button>
      </div>
    </div>
      <el-input
      v-model="search"
      v-if = "level == 1"
      placeholder="查询单位名称"
      @input = "tables"
      style="width: 10vw;"
      clearable
     ></el-input>
     <el-tree  :data="unitlist" highlight-current :props="defaultProps" @node-click="handleNodeClick" style="width: 10vw;padding: 10px 10px 0 0;"></el-tree>
     <div class="table-container">
      <el-table :data="tableData" :row-class-name="tableRowClassName"  :row-style="{height: '0.1'}" :cell-style="{padding: '4px -15px'}">
        <el-table-column prop="unitname" label="单位名称" align="center"></el-table-column>
        <el-table-column prop="province" label="所在省份" align="center"></el-table-column>
        <el-table-column prop="contactname" label="联系人姓名" align="center"></el-table-column>
        <el-table-column prop="phone" label="手机号码" align="center"></el-table-column>
        <el-table-column prop="address" label="办公地址" align="center"></el-table-column>
        <el-table-column prop="createdon" label="创建日期" align="center"></el-table-column>
        <el-table-column prop="createdby" label=" 创建者" align="center"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
                <el-tag type="success" effect="dark" v-if="scope.row.active=='启用'">启用</el-tag>
                <el-tag type="danger" effect="dark" v-if="scope.row.active=='停用'">停用</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center">
          <template slot-scope="scope">
            <el-button-group>
              <el-button @click="showEditDia(scope.row)" type="text"  style="margin-right:10px">编辑</el-button>
              <el-button @click="showEditDialNews(scope.row)" type="text" style="margin-right:10px" >详情</el-button>
              <el-button @click="showlog(scope.row)" type="text" style="margin-right:10px" >日志</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
  <AddUnit
        :title="addUnitTitle"
        :addUnitVisible="addUnitVisible"
        :level="level"
        @val-change="addUnit"
        @cancel="addUnitget"
        :close-on-click-modal = "false"
    ></AddUnit>
    <AddTwoUnit
        :title="addTwoUnitTitle"
        :addTwoUnitVisible="addTwoUnitVisible"
        :level="level"
        :levelunit="levelunit"
        @val-change="addTwoUnit"
        @cancel="addTwoUnitget"
        :close-on-click-modal = "false"
    ></AddTwoUnit>
    <AddThreeUnit
        :title="addThreeUnitTitle"
        :addThreeUnitVisible="addThreeUnitVisible"
        :level="level"
        :levelunit="levelunit2"
        @val-change="addThreeUnit"
        @cancel="addThreeUnitget"
        :close-on-click-modal = "false"
    ></AddThreeUnit>
  <div>
  <el-dialog
      title="编辑单位信息"
      :visible.sync="editDiaVisible"
      width="800px"
      :before-close = "editDiaClosed"
      :close-on-click-modal = "false"
      >
       <el-form label-position="right" label-width="100px" :inline="true" :model="editForm"  ref="editunit"
                     style="padding: 0px 50px 0px 10px;" class="demo-ruleForm"  :rules="rules"  @open="openAddTwoUnit()">
          <el-form-item label="单位名称">
              <el-input v-model="editForm.unitname" disabled placeholder="名称单位"></el-input>
          </el-form-item>
          <el-form-item label="所在省份" >
              <el-input v-model="editForm.province" disabled placeholder="所在省份"  ></el-input>
          </el-form-item>
          <el-form-item label="联系人姓名" prop="contactname">
            <el-input v-model="editForm.contactname" clearable placeholder="联系人姓名" ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
              <el-input v-model="editForm.phone" clearable placeholder="联系电话"></el-input>
          </el-form-item>
          <el-form-item label="办公地址" prop="address">
              <el-input  v-model="editForm.address" clearable placeholder="办公地址" show-mailbox></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="active">
              <el-select v-model="editForm.active" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDiaClosed">取 消</el-button>
        <el-button type="primary" @click="editUnitInfo('editunit')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
  <div>
  <el-dialog
      title="单位详情"
      :visible.sync="editDialNewsVisible"
      width="800px"
      :before-close = "editDialNewsClosed"
      :close-on-click-modal = "false"
      >
       <el-form label-position="right" label-width="100px" :inline="true" :model="editForm1"
                     style="padding: 0px 50px 0px 10px;" class="demo-ruleForm" :rules="rules" ref="editForm1">
          <el-form-item label="单位名称" >
              <el-input v-model="editForm1.unitname" clearable placeholder="单位名称" disabled></el-input>
          </el-form-item>
          <el-form-item label="所在省份">
              <el-input v-model="editForm1.province" clearable placeholder="所在省份" disabled ></el-input>
          </el-form-item>
          <el-form-item label="联系人姓名">
            <el-input v-model="editForm1.contactname" placeholder="联系人姓名" disabled></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
              <el-input v-model="editForm1.phone" clearable placeholder="联系电话" disabled></el-input>
          </el-form-item>
          <el-form-item label="办公地址">
              <el-input v-model="editForm1.address" placeholder="办公地址" disabled></el-input>
          </el-form-item>
          <el-form-item label="状态" >
             <el-input v-model="editForm1.active"  disabled></el-input>
          </el-form-item>
        </el-form>
              <p style="margin-top: 30px;margin-bottom: 10px;margin-left: 30px;">单位绑定机器人详情</p>
              <el-table
                :data="dataList"
                height="250"
                border
                style="width: 80%;margin-top: 20px;margin-left: 80px;">
                <el-table-column
                  prop="robotname"
                  label="机器人名称">
                </el-table-column>
                <el-table-column
                  prop="createdon"
                  label="绑定时间">
                </el-table-column>
                <el-table-column
                  prop="active"
                  label="机器人状态">
                </el-table-column>
              </el-table>
            <span slot="footer" class="dialog-footer">
        <el-button @click="editDialNewsClosed">返回</el-button>
      </span>
    </el-dialog>
  </div>
  <div>
  <el-dialog
      title="查看日志"
      :visible.sync="editDialogVisible"
      width="800px"
      :before-close = "editDialogClosed"
      :close-on-click-modal = "false"
      >
      <el-table :data="tablelog">
        <el-table-column prop="createdby" label="操作人" ></el-table-column>
        <el-table-column prop="lastupdateon" label="操作时间" ></el-table-column>
        <el-table-column prop="unittype" label="操作动作"></el-table-column>
        <el-table-column prop="content" label="日志内容"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</div>
</template>

<script>
import AddUnit from './addunits.vue'
import AddTwoUnit from './add_two_units.vue'
import AddThreeUnit from './add_three_units.vue'

export default {
  name: 'unit',
  components: {
    AddUnit,
    AddTwoUnit,
    AddThreeUnit
  },
  data () {
    var checkphone = (rule, value, callback) => {
      if (!value || value === '') {
        return callback(new Error('手机号码不能为空'))
      }
      if (!(/^1[3|4|5|7|8|9][0-9]\d{4,8}$/.test(value))) {
        callback(new Error('请输入合法的手机号码'))
      } else {
        callback()
      }
    }
    var checkmailbox = (rule, value, callback) => {
      if (!value || value === '') {
        return callback(new Error('邮箱不能为空'))
      }
      if (!(/^\w+@\w+(\.\w+)+$/.test(value))) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    }
    return {
      unitlevel: '',
      search: '',
      node: [],
      tableData: [],
      tablelog: [],
      state: '',
      addUnitVisible: false,
      addTwoUnitVisible: false,
      addThreeUnitVisible: false,
      addUnitTitle: '一级单位创建',
      addTwoUnitTitle: '二级单位创建',
      addThreeUnitTitle: '三级单位创建',
      editDiaVisible: false,
      editDialNewsVisible: false,
      editDialogVisible: false,
      editForm: {
        unitname: '',
        province: '',
        contactname: '',
        phone: '',
        address: '',
        active: ''
      },
      editForm1: {
        unitname: '',
        province: '',
        contactname: '',
        phone: '',
        address: '',
        active: ''
      },
      editForm2: {
        createdby: '',
        lastupdateon: '',
        unittype: '',
        content: ''
      },
      options: [{
        value: '启用',
        label: '启用'
      }, {
        value: '停用',
        label: '停用'
      }],
      rules: {
        contactname: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: checkphone }
        ],
        mailbox: [
          { required: true, validator: checkmailbox, trigger: 'blur' }
        ]
      },
      roles: [{
        value: '1',
        label: '超级管理员'
      }, {
        value: '2',
        label: '管理员'
      }, {
        value: '3',
        label: '普通用户'
      }
      ],
      unitlist: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      dataList: [],
      datas: [],
      typelist: [],
      checkAll: [],
      level: '',
      levelunit: [],
      levelunit2: []
    }
  },
  created () {
    this.level = localStorage.getItem('role_id')
    this.unitlevel = localStorage.getItem('unit_level')
    this.getdatas()
    this.getunitlist()
  },
  methods: {
    tables () {
      const search = this.search
      if (search) {
        this.axios.post('/units/unitlistsearch/', {
          unitname: search
        }).then((res) => {
          this.unitlist = res.data.data
        }).catch((error) => {
          console.log(error)
        })
      } else {
        this.getunitlist()
      }
    },
    getlevelunit () {
      this.axios
        .post('/units/getlevelunit/', {
          level: 1
        }).then((res) => {
          this.levelunit = res.data.data
        })
    },
    getlevelunit2 () {
      this.axios
        .post('/units/getlevelunit/', {
          level: 2
        }).then((res) => {
          this.levelunit2 = res.data.data
        })
    },
    spandatas (typeid) {
      console.log('itemid', typeid)
      this.axios
        .post('/units/unitcanbindingrobot/', {
          unitid: localStorage.getItem('unit_id'),
          rtid: typeid
        }).then((res) => {
          console.log('res', res.data.data)
          this.datas = res.data.data
        })
    },
    handleNodeClick (data) {
      this.axios.post('/units/', {
        unitid: data.id
      }).then((res) => {
        this.tableData = res.data.data
      }).catch((error) => {
        console.log(error)
      })
    },
    getunitlist () {
      this.axios.post('/units/unitlist/', {
        unitid: localStorage.getItem('unit_id'),
        unituser: localStorage.getItem('role_name')
      }).then((res) => {
        this.unitlist = res.data.data
      }).catch((error) => {
        console.log(error)
      })
    },
    getdatas () {
      this.axios.post('/units/', {
        unitid: localStorage.getItem('unit_id')
      }).then((res) => {
        console.log('tableData', res.data.data)
        this.tableData = res.data.data
      }).catch((error) => {
        console.log(error)
      })
    },
    showEditDia (row) {
      this.editForm = row
      this.editDiaVisible = true
    },
    showEditDialNews (row) {
      console.log('row', row.uid)
      this.editForm1 = row
      // 获取该单位绑定的机器人
      this.axios.post('/units/queryunitsrobot/', {
        unitid: row.uid
      }).then((res) => {
        this.dataList = res.data.data
      }).catch((error) => {
        console.log(error)
      })

      this.editDialNewsVisible = true
    },
    showlog (row) {
      console.log('row', row)
      this.axios.post('/units/unitlog/', {
        unitname: row.unitname
      }).then((res) => {
        this.tablelog = res.data.data
      }).catch((error) => {
        console.log(error)
      })
      this.editDialogVisible = true
    },
    addUnitget () {
      this.getdatas()
      this.getunitlist()
      this.addUnitVisible = false
    },
    addTwoUnitget () {
      this.getdatas()
      this.getunitlist()
      this.addTwoUnitVisible = false
    },
    addThreeUnitget () {
      this.getdatas()
      this.getunitlist()
      this.addThreeUnitVisible = false
    },
    editDiaClosed () {
      // this.$refs.editFormRef.resetFields()
      this.editDiaVisible = false
    },
    editDialNewsClosed () {
      // this.$refs.editFormRef1.resetFields()
      this.editDialNewsVisible = false
    },
    editDialogClosed () {
      // this.$refs.editFormRef1.resetFields()
      this.editDialogVisible = false
    },
    editUnitInfo (checkdatas) {
      var datas = this.editForm
      this.$refs[checkdatas].validate((valid) => {
        if (valid) {
          this.axios
            .post('/units/editunit/', {
              datas,
              createdby: localStorage.getItem('yun_user')
            }).then((res) => {
              if (res.data.res === false) {
                this.$message.warning('上级单位处于冻结状态，无法解冻！')
              } else {
                this.axios.post('/units/', {
                  unitid: this.editForm.uid
                }).then((res) => {
                  this.tableData = res.data.data
                  this.editDiaVisible = false
                  this.$message.success('更新用户信息成功！')
                }).catch((error) => {
                  console.log(error)
                })
              }
            })
        } else {
          this.$message.warning('校验未通过！')
          return false
        }
      })
    },
    editAddUnit () {
      this.addUnitVisible = true
    },
    addTwoUnit () {
      this.getlevelunit()
      this.addTwoUnitVisible = true
    },
    addThreeUnit () {
      this.getlevelunit2()
      this.addThreeUnitVisible = true
    },
    addUnit (data) {
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'success-row'
      }
    }
  }
}
</script>

<style scoped>

.table-container {
  display: flex;
  flex-direction: column;
  height: 100;
  position: relative;
  margin-left: 140px;
  margin-top:  -50px;
}

.unit-management {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}
.unit-management strong {
  font-size: 2vw;
}
.buttons {
  display: flex;
  gap: 10px;
}

.el-table .warning-row {
  background: oldlace;
}
.el-divider{
   margin: 1px;
}

.Attopic{display:block; left: 56px; position: absolute; top: 68px; z-index: 999; background: #fff; border: 1px solid #ccc; padding: 2px; width: 180px; height: 100px; overflow-x:hidden; overflow-y:hidden;}

</style>
