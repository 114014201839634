<template>
    <div>
        <el-dialog :title="title" :visible.sync="addTwoUnitVisible"  width="800px"
                   :before-close="handleCancel" :close-on-click-modal="false" @open="openAddUnit()">
          <div>
              <el-form label-position="right" label-width="100px" :inline="true" :model="unitInfo"  :rules="rules" ref="unit"
                     style="padding: 0px 50px 0px 10px;" class="demo-ruleForm">
                  <el-form-item label="单位名称" prop="unitname">
                    <el-input v-model="unitInfo.unitname" clearable placeholder="请输入单位名称">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="所在省份" prop="province">
                      <el-input v-model="unitInfo.province" clearable placeholder="请输入所在省份"></el-input>
                  </el-form-item>
                  <el-form-item label="联系人姓名" prop="contactname">
                  <el-input v-model="unitInfo.contactname"  clearable placeholder="请输入联系人姓名"></el-input>
                  </el-form-item>
                  <el-form-item label="联系电话" prop="phone">
                  <el-input v-model="unitInfo.phone" clearable placeholder="请输入联系电话"></el-input>
                  </el-form-item>
                  <el-form-item label="电子邮箱" prop="mailbox">
                  <el-input v-model="unitInfo.mailbox" clearable placeholder="请输入电子邮箱"></el-input>
                  </el-form-item>
                  <el-form-item label="办公地址" prop="address">
                  <el-input v-model="unitInfo.address" clearable placeholder="请输入办公地址"></el-input>
                  </el-form-item>
                  <el-form-item label="上级单位" prop="parentid" >
                  <el-select v-model="unitInfo.parentid" @change="clear"   placeholder="请选择上级单位">
                    <el-option
                      v-for="item in levelunit"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                </el-form>
          </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSubmit('unit')">确定</el-button>
                <el-button @click="resetForm('unit')">重置信息</el-button>

            </div>
          <div >
                <div style="margin-top: 30px;">
                单位机器人绑定:
                   <div style="margin-bottom: 20px;margin-left: 80px;">
                      <el-button  round  v-for = "item in typelist" :key="item.id" style="width:130px;line-height:20px;margin-top:20px"  @click="spandatas(item.id,unitInfo.parentid)" >{{ item.typename }}</el-button>
                  </div>
                </div>
                <div style="margin-top: 15px;;margin-left: 80px;">
                  <el-checkbox-group v-model="checkAll">
                    <el-checkbox v-for = "item in datas" :key="item.id" :label="item.id" style="width:100px;float:center;margin-right:120px;line-height:20px;margin-top:20px" >{{ item.robotname }}</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
  name: 'AddTwoUnit',
  props: {
    addTwoUnitVisible: Boolean,
    data: [Object, Boolean],
    title: String,
    level: String,
    levelunit: [Array, Object]
  },
  data () {
    var checkphone = (rule, value, callback) => {
      if (!value || value === '') {
        return callback(new Error('手机号码不能为空'))
      }
      if (!(/^1[3|4|5|7|8|9][0-9]\d{4,8}$/.test(value))) {
        callback(new Error('请输入合法的手机号码'))
      } else {
        callback()
      }
    }
    var checkmailbox = (rule, value, callback) => {
      if (!value || value === '') {
        return callback(new Error('邮箱不能为空'))
      }
      if (!(/^\w+@\w+(\.\w+)+$/.test(value))) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    }
    var checkname = (rule, value, callback) => {
      if (!value || value === '') {
        return callback(new Error('单位名称不能为空'))
      } else {
        this.axios
          .post('/units/checkname/', {
            unitname: value
          }).then((res) => {
            console.log('resss', res.data.res)
            if (res.data.res === false) {
              callback(new Error('单位名称重复！'))
            } else {
              callback()
            }
          })
      }
    }
    return {
      rules: {
        unitname: [
          { required: true, validator: checkname, trigger: 'blur' }
        ],
        province: [
          { required: true, message: '请输入省份', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        contactname: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: checkphone }
        ],
        mailbox: [
          { required: true, validator: checkmailbox, trigger: 'blur' }
        ],
        parentid: [
          { required: true, trigger: 'blur' }
        ]
      },
      unitInfo: {
        unitname: '',
        province: '',
        contactname: '',
        phone: '',
        mailbox: '',
        address: '',
        parentid: ''
      },
      typelist: [],
      checkAll: [],
      datas: []
    }
  },
  methods: {
    clear () {
      this.datas = []
      this.checkAll = []
    },
    openAddUnit () {
      this.axios
        .post('/units/unitbindingrobot/', {
          unitid: localStorage.getItem('unit_id')
        }).then((res) => {
          this.typelist = res.data.robot
        })
    },
    spandatas (typeid, parentid) {
      if (parentid === '' || parentid == null) {
        return this.$message.warning('请选择上级单位！')
      }
      this.axios
        .post('/units/twocanbindingrobot/', {
          unitid: localStorage.getItem('unit_id'),
          rtid: typeid,
          pid: parentid
        }).then((res) => {
          console.log('res', res.data.data)
          this.datas = res.data.data
        })
    },
    // 关闭弹窗
    handleCancel () {
      this.$refs.unit.resetFields()
      this.checkAll = []
      this.datas = []
      this.$emit('cancel')
    },
    onSubmit (checkdatas) {
      var datas = this.unitInfo
      this.$refs[checkdatas].validate((valid) => {
        if (valid) {
          this.axios
            .post('/units/insertunit/', {
              datas,
              level: 2,
              createdby: localStorage.getItem('yun_user'),
              robotlist: this.checkAll
            }).then((res) => {
              if (res.data.res === true) {
                this.$message.success('创建成功！')
                this.$emit('cancel')
              } else {
                this.$message.warning('创建失败！')
              }
            })
          this.resetForm()
        } else {
          this.$message.warning('校验未通过！')
          return false
        }
      })
    },
    resetForm (unit) {
      this.$refs.unit.resetFields()
      this.checkAll = []
      this.datas = []
    }
  }
}
</script>

<style scoped>
     .Attopic{display:block; left: 56px; position: absolute; top: 68px; z-index: 999; background: #fff; border: 1px solid #ccc; padding: 2px; width: 180px; height: 100px; overflow-x:hidden; overflow-y:hidden;}

</style>
